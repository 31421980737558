import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

const NewSite = ({ jurisdictions, site }) => {
  const [jurisdiction, setJurisdiction] = useState()
  const [title, setTitle] = useState('')
  const [subsoilDepth, setSubsoilDepth] = useState(3)
  const [applyGuidelines, setApplyGuidelines] = useState(false)
  const [jurisdictionOptions, setJurisdictionOptions] = useState([])

  useEffect(() => {
    if (jurisdictions) {
      const formattedJurisdictions = jurisdictions.map(jurisdiction => {
        return { label: jurisdiction.name, value: jurisdiction.id }
      }).filter(jurisdiction => jurisdiction.label != 'British Columbia' && jurisdiction.label != 'Saskatchewan')
      setJurisdictionOptions(formattedJurisdictions)
    }
  }, [jurisdictions])

  useEffect(() => {
    if (site) {
      setTitle(site.title)
      setSubsoilDepth(site.subsoil_depth)
      setApplyGuidelines(site.apply_guidelines)
    } else {
      setApplyGuidelines(true)
    }
  }, [site])

  useEffect( () => {
    if (jurisdictionOptions.length > 0) {
      if (site) {
        setJurisdiction(jurisdictionOptions.filter(jurisdiction => jurisdiction.value == site.jurisdiction_id)[0])
      } else {
        setJurisdiction(jurisdictionOptions.filter(jurisdiction => jurisdiction.label == 'Alberta')[0])
      }
    }
  }, [jurisdictionOptions, site])

  const createSite = () => {
    if (title.length < 1) {
      return toast.error('Please fill out all required fields')
    }

    if (site) {
      return axios.put(`/sites/${site.id}.json`, { apply_guidelines: applyGuidelines, title: title, subsoil_depth: subsoilDepth, jurisdiction_id: jurisdiction.value })
        .then(res => {
          toast.success('Updating site...')
          window.location.href = res.data
        })
    }

    return axios.post(`/sites.json`, { apply_guidelines: applyGuidelines, title: title, subsoil_depth: subsoilDepth, jurisdiction_id: jurisdiction.value })
      .then(res => {
        toast.success('Creating site...')
        window.location.href = res.data
      })
  }

  return <>
    <ToastContainer />
    <h1>New Site</h1>
    <div className='flex flex-col gap-y-2 w-full ml-2 mt-2'>
      <div className='flex flex-col w-80'>
        <label>* Title</label>
        <input
          className='border border-gray-300 rounded-md h-10 px-2'
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
      </div>
      <div className='flex flex-col w-80'>
        <label>* Subsoil Depth</label>
        <input
          type="number"
          step="0.1"
          className='border border-gray-300 rounded-md h-10 px-2'
          value={subsoilDepth}
          onChange={e => setSubsoilDepth(e.target.value)}
        />
      </div>
      <div className='flex flex-row gap-x-3 my-1 -ml-1'>
        <input
          type="checkbox"
          className='border border-gray-300 rounded-md w-8'
          checked={applyGuidelines}
          onChange={e => setApplyGuidelines(e.target.checked)}
        />
        <label className='w-full'>* Apply Guidelines (these can be applied at anytime from site details page)</label>
      </div>
      {applyGuidelines && <div className='w-80'>
        <label>Guidelines</label>
        <Select
          options={jurisdictionOptions}
          value={jurisdiction}
          onChange={setJurisdiction}
        />
      </div>}
      <button className='btn btn-default w-32 font-semibold'
        onClick={() => createSite()}
      >
        Next
      </button>
    </div>
  </>
}

export default NewSite
